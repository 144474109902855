<template>
	<label :for="label" class="font-bold text-lg text-gray-700">{{ label }}</label>
	<div v-if="subtitle" class="font-normal text-sm mt-0.5">
		{{ subtitle }}
	</div>
	<div :class="input_group_class" class="input-group  relative mt-1">
		<slot v-if="slots.default"/>
		<textarea
				v-if="type === 'textarea' && (!use_only_slot || !slots.default)"
				:disabled="disabled"
				:placeholder="placeholder"
				:rows="textarea_rows"
				@input="update($event.target.value)"
				class="form-input"
				:class="{
					'text-gray-700': disabled
				}"
		>{{ textAreaDefaultValue }}</textarea>
		<input
				v-if="type !== 'textarea' && (!use_only_slot || !slots.default)"
				:type="type"
				:disabled="disabled"
				:autocomplete="autocomplete"
				:value="parent_ref"
				:placeholder="placeholder"
				@input="update($event.target.value)"
				class="form-input"
				:class="{
					'text-gray-500': disabled
				}"
		>
		<!--
		isCorrect is a reactive variable that is used to show tick or cross icons based on the input.
		It is set to 0 by default, 1 if the input is incorrect, and 2 if the input is correct.
		-->
		<div v-if="isCorrect !== 0" class="absolute right-3 w-4 h-4 top-1/2 bottom-1/2 -translate-y-1/2"
		     :class="{
                    'is-correct--true': isCorrect === 2,
                    'is-correct--false': isCorrect === 1,
                 }"
		/>
	</div>
</template>

<script setup>
const props = defineProps({
	label: {
		type: String,
		required: true,
	},
	subtitle: {
		type: String,
		default: null,
	},
	parent_ref: {
		type: String,
	},
	type: {
		type: String,
		default: "text",
	},
	placeholder: {
		type: String,
		default: "",
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	check: {
		type: Function,
		default: null
	},
	autocomplete: {
		type: String,
		default: "off",
	},
	textarea_rows: {
		type: Number,
		default: 3,
	},
	input_group_class: {
		type: String,
		default: "",
	},
	use_only_slot: {
		type: Boolean,
		default: true,
	}
});

const textAreaDefaultValue = props.type === "textarea" ? props.parent_ref : "";

const slots = useSlots();

const emit = defineEmits(["update:parent_ref"]);
const refVal = toRef(props, "parent_ref");
const update = (data) => {
	if (props.type === "number") {
		data = Number(data);
	}
	emit("update:parent_ref", data);
}

const isCorrect = ref(0);

if (props.check) {
	watch(refVal, (val) => {
		const check = props.check(val);
		if (check && !val) {
			isCorrect.value = 0;
			return;
		}
		isCorrect.value = Number(props.check(val)) + 1;
	});
}
</script>